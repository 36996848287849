import { D } from '@dayetopia/library';
import getOmsUrl from '../../getOmsUrl';

interface Variant {
  id: string;
  count: number;
}

export default async function useBoxPrice(variantsDict: Variant) {
  if (!variantsDict) return null;

  const variants: any = {};
  variants[variantsDict.id] = variantsDict.count;
  const boxPrice = await getPrice(variants);
  return boxPrice;

  async function getPrice(variants: any) {
    const oms = getOmsUrl(window.location.hostname);
    const url = `${oms}/orders/calculate-price/contraception_box`;
    const body = JSON.stringify(variants);
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body,
    };
    return fetch(url, options)
      .then(res => D.check200(res))
      .then(json => json)
      .catch(err => {
        throw err;
      });
  }
}
