import React, { useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { D } from '@dayetopia/library';

import Button from './Button';
import ContentWrapper from './ContentWrapper';
import Arrow from './RecommendationArrow';
import SlideTopBox from './RecommendationSlideTopBox';
import SlideBottomBox from './RecommendationBottomBox';
import { Context } from './Context';
import { Recommendation, PillFromPrismic } from '../types';

interface SlideProps {
  recommendation: Recommendation;
  match: number;
  activeSlideIndex: number;
  handlePrevArrowClick: (a: any) => void;
  handleNextArrowClick: (a: any) => void;
  slideInfoFromPrismic: PillFromPrismic;
  setPillRegimenDrawerOpen: any;
  setPillDrawerOpen: any;
  pillRegimenDrawerOpen: any;
}
export default function RecommendationSlide({
  recommendation,
  match,
  activeSlideIndex,
  handlePrevArrowClick,
  handleNextArrowClick,
  slideInfoFromPrismic,
  setPillRegimenDrawerOpen,
  setPillDrawerOpen,
  pillRegimenDrawerOpen,
}: SlideProps) {
  const { dispatch } = useContext(Context);
  const isMatch = activeSlideIndex === match;
  const showLeftArrow = activeSlideIndex !== 0 && isMatch;
  const showRightArrow = activeSlideIndex !== 2 && isMatch;

  function goCheckout() {
    dispatch({ type: 'setSelectedBox', data: recommendation });
    navigate('/checkout/login');
  }

  console.log(recommendation);

  return (
    <RightContent>
      <BeigeContainer isMatch={isMatch}>
        <SlideTopBox recommendation={recommendation} match={match} />
        <SlideBottomBox
          recommendation={recommendation}
          slideInfoFromPrismic={slideInfoFromPrismic}
          setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
          setPillDrawerOpen={setPillDrawerOpen}
        />
        <StyledBuyButton onClick={goCheckout}>Buy now</StyledBuyButton>
        {showLeftArrow && !pillRegimenDrawerOpen && (
          <Arrow
            direction="left"
            onArrowClick={handlePrevArrowClick}
            activeSlideIndex={activeSlideIndex}
          />
        )}
        {showRightArrow && !pillRegimenDrawerOpen && (
          <Arrow
            direction="right"
            onArrowClick={handleNextArrowClick}
            activeSlideIndex={activeSlideIndex}
          />
        )}
      </BeigeContainer>
    </RightContent>
  );
}

interface BeigeContainer {
  isMatch: boolean;
}

interface Arrow {
  onClick: any;
}

const RightContent = styled(ContentWrapper)`
  max-width: none;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  padding-top: 10px;
`;

const BeigeContainer = styled.div`
  position: relative;
  outline: none;
  border: 2px solid #00391e;
  box-sizing: border-box;
  border-radius: 1px;
  width: 90%;
  height: 670px;
  transition-duration: 0.4s, 0.8s, 0.4s;
  transition: opacity background 0.5s;
  ${({ isMatch }: BeigeContainer) => {
    if (isMatch) {
      return 'opacity: 1; background:#fefcea;';
    } else {
      return 'opacity:  0.5;  background:#EAF5E8;';
    }
  }}
  @media only screen and (max-width: 1024px) {
    width: 90%;
    height: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    opacity: 1;
    background: #fefcea;
  }
`;

const StyledBuyButton = styled(Button)`
  margin: 15px;
  margin-top: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`;
