import Prismic from 'prismic-javascript';
import { prismicCRE } from './prismic-config';

export async function getPillsFromPrismic() {
  return await prismicCRE
    .query(Prismic.Predicates.at('document.type', 'pill'), {
      fetch: [],
    })
    .catch(err => console.log('No pills found', err));
}
