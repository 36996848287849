import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import RecommendationLeftContent from '../components/RecommendationLeftContent';
import RecommendationSliderContainerDesktop from '../components/RecommendationSliderContainerDesktop';
import RecommendationSliderContainerMobile from '../components/RecommendationSliderContainerMobile';
import RegimensDrawer from '../components/RecommendationRegimensDrawer';
import PillInfoDrawer from '../components/RecommendationPillInfoDrawer';
import { PillFromPrismic } from '../types';
import { getPillsFromPrismic } from '../prismic-requests';

export default function Recommendation() {
  const [sideEffects, setSideEffects] = useState(undefined);
  const [pillRegimens, setPillRegimens] = useState(undefined);
  const [allPills, setAllPills] = useState([]);
  const [pillRegimenDrawerOpen, setPillRegimenDrawerOpen] = useState(false);
  const [pillDrawerOpen, setPillDrawerOpen] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState('');

  useEffect(() => {
    getPillsFromPrismic().then(pills => {
      processPillsFromPrismic(pills);
    });
  }, []);

  function processPillsFromPrismic(data) {
    const { results } = data;
    const sideEffects = results.find(
      (r: PillFromPrismic) => r.type === 'side_effects'
    );
    const pillRegimens = results.find(
      (r: PillFromPrismic) => r.type === 'pill_regimens'
    );
    const pills = results.filter((r: PillFromPrismic) => r.type === 'pill');
    setSideEffects(sideEffects);
    setPillRegimens(pillRegimens);
    setAllPills(pills);
  }

  const activeSlidePill =
    allPills &&
    allPills.find((p: PillFromPrismic) => p.id.includes(activeSlideId));

  const isDesktop = window.innerWidth > 1100;

  return (
    <PageContainer>
      <RecommendationContent>
        <RecommendationLeftContent sideEffects={sideEffects} />
        {allPills &&
          (isDesktop ? (
            <RecommendationSliderContainerDesktop
              setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
              setPillDrawerOpen={setPillDrawerOpen}
              pillRegimenDrawerOpen={pillRegimenDrawerOpen}
              setActiveSlideId={setActiveSlideId}
              allPills={allPills}
            />
          ) : (
            <RecommendationSliderContainerMobile
              activeSlidePill={activeSlidePill}
              setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
              setPillDrawerOpen={setPillDrawerOpen}
              setActiveSlideId={setActiveSlideId}
              allPills={allPills}
            />
          ))}
      </RecommendationContent>
      <RegimensDrawer
        pillRegimenDrawerOpen={pillRegimenDrawerOpen}
        setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
        pillRegimens={pillRegimens}
      />
      <PillInfoDrawer
        pillDrawerOpen={pillDrawerOpen}
        setPillDrawerOpen={setPillDrawerOpen}
        activePillObj={activeSlidePill}
      />
    </PageContainer>
  );
}

const RecommendationContent = styled.div`
  display: flex;
  min-height: calc(100vh - 100px);
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    max-height: none;
  }
`;

const PageContainer = styled.div`
  position: relative;
  animation: fadein 0.8s;
`;
