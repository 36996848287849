import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { Context } from './Context';
import SliderContent from './RecommendationSliderContent';
import { PillFromPrismic, Recommendation } from '../types';

interface Props {
  setPillRegimenDrawerOpen: (a: boolean) => void;
  setPillDrawerOpen: (a: boolean) => void;
  pillRegimenDrawerOpen: any;
  setActiveSlideId: (a: string) => void;
  allPills: PillFromPrismic[];
}

export default function RecommendationSliderContainerDesktop({
  setPillRegimenDrawerOpen,
  setPillDrawerOpen,
  pillRegimenDrawerOpen,
  setActiveSlideId,
  allPills,
}: Props) {
  const { state } = useContext(Context);

  const [slideState, setSlideState] = useState({
    activeSlideIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { translate, activeSlideIndex, transition } = slideState;
  let recommendations: Recommendation[] = [];

  if (!state.recommendation) {
    localStorage.clear();
    navigate('/questionnaire-landing');
  }
  if (state.recommendation) {
    recommendations = Object.values(state.recommendation);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!recommendations[activeSlideIndex]) return;
    setActiveSlideId(recommendations[activeSlideIndex].contentId);
  }, [slideState]);

  const handleResize = () => {
    setSlideState({
      ...slideState,
      translate: getWidth() * activeSlideIndex,
      transition: 0.45,
    });
  };

  const getWidth = () => window.innerWidth / 2 - 105;

  const nextSlide = (activeSlideIndex: number) => {
    if (activeSlideIndex === recommendations.length - 1) {
      return setSlideState({
        ...slideState,
        translate: 0,
        activeSlideIndex: 0,
      });
    }
    const padding = activeSlideIndex * 50;
    setSlideState({
      ...slideState,
      activeSlideIndex: activeSlideIndex + 1,
      translate: (activeSlideIndex + 1) * getWidth() + padding,
    });
  };

  const prevSlide = (activeSlideIndex: number) => {
    if (activeSlideIndex === 0) {
      return setSlideState({
        ...slideState,
        translate: (recommendations.length - 1) * getWidth(),
        activeSlideIndex: recommendations.length - 1,
      });
    }

    setSlideState({
      ...slideState,
      activeSlideIndex: activeSlideIndex - 1,
      translate: (activeSlideIndex - 1) * getWidth(),
    });
  };

  return (
    <SliderContainer>
      {recommendations && (
        <SliderContent
          translate={translate}
          transition={transition}
          width={getWidth() * recommendations.length}
          activeSlideIndex={activeSlideIndex}
          handlePrevArrowClick={prevSlide}
          handleNextArrowClick={nextSlide}
          allPills={allPills}
          setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
          setPillDrawerOpen={setPillDrawerOpen}
          pillRegimenDrawerOpen={pillRegimenDrawerOpen}
        />
      )}
    </SliderContainer>
  );
}

const SliderContainer = styled.div`
  background: #cae2c3;
  position: relative;
  width: 60vw;
  padding: 60px 0;
  /* height: 90vh;
  height: calc(100vh - 100px); */
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    overflow-x: visible;
    border: 0.5px #cae2c3 solid;
    height: auto;
  }
`;
