import React from 'react';
import styled from 'styled-components';
import arrow from '../images/arrow-right.svg';

interface Props {
  direction: any;
  onArrowClick: any;
  activeSlideIndex: any;
}
export default function RecommendationArrow({
  direction,
  onArrowClick,
  activeSlideIndex,
}: Props) {
  const right = direction === 'right';
  return (
    <Container right={right}>
      <ArrowStyled
        right={right ? 1 : 0}
        onClick={() => onArrowClick(activeSlideIndex)}
      />
    </Container>
  );
}

interface Container {
  right: any;
}

const Container = styled.div`
  z-index: 999;
  display: flex;
  position: absolute;
  top: 50%;
  ${({ right }: Container) => (right ? `right: -23px` : `left: -22px`)};
  justify-content: center;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

interface Arrow {
  right: string;
}

const ArrowStyled = styled(arrow)`
  z-index: 9;
  ${({ right }: Arrow) => (right ? null : ' transform: rotate(-180deg)')}
`;
