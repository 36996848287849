import React from 'react';
import { RichText } from 'prismic-reactjs';
import { D } from '@dayetopia/library';

export default function RegimensDrawer({
  pillRegimenDrawerOpen,
  setPillRegimenDrawerOpen,
  pillRegimens,
}) {
  return (
    <D.Drawer
      drawerOpen={pillRegimenDrawerOpen}
      setDrawerOpen={setPillRegimenDrawerOpen}
      title=""
    >
      <div className="rich-text">
        {pillRegimens && RichText.render(pillRegimens.data.page_title)}
        {pillRegimens && RichText.render(pillRegimens.data.content)}
      </div>
    </D.Drawer>
  );
}
