import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import { RichText } from 'prismic-reactjs';

import reco from '../images/ing-reco.png';
import ContentWrapper from './ContentWrapper';

export default function RecommendationLeftContent({ sideEffects }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <LeftContent>
      <RecoImgStyled src={reco} />
      <H1Styled>Our recommendations</H1Styled>
      <PStyled>
        Based on what you've told us, we think you could benefit from changing
        the type of pill you are on.
      </PStyled>
      <PStyled>
        We also think you might be more suited to taking the pill in a slightly
        different way. The way you take the pill (and have breaks) can give you
        more control over bleeding patterns, any symptoms you have linked to
        bleeding and your pill free week.
      </PStyled>
      <D.Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} title="">
        <div className="rich-text">
          {sideEffects && RichText.render(sideEffects.data.content)}
        </div>
      </D.Drawer>
      <PStyled2>
        ‼️ Learn about general pills
        <StyledLink onClick={() => setDrawerOpen(true)}>
          risks and benefits
        </StyledLink>
      </PStyled2>
    </LeftContent>
  );
}

const LeftContent = styled(ContentWrapper)`
  background: #eaf5e8;
  margin: 0;
  padding: 30px 60px;
  min-height: 100%;
  text-align: center;
  width: 50vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    background: #cae2c3;
    width: 100%;
    height: auto;
    -webkit-flex: 0;
    flex-shrink: 0;
  }
`;

const RecoImgStyled = styled.img`
  max-height: 190px;
  max-width: 140px;
  width: 25%;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const H1Styled = styled(D.H1)`
  font-size: 28px;
  text-align: center;
  margin: 20px 0 30px;
  @media only screen and (max-width: 1024px) {
    font-size: 24px;
  }
`;

const PStyled = styled(D.P)`
  font-size: 16px;
  text-align: center;
  margin: 0 auto 30px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 0;
    font-size: 14px;
  }
`;

const PStyled2 = styled(D.P2)`
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 500px) {
    margin-bottom: 30px;
    font-size: 14px;
  }
`;

const StyledLink = styled(D.A)`
  text-decoration: none;
  cursor: pointer;
  padding-top: 6px;
  margin-left: 6px;
  border-bottom: 2px solid #01341a;
  color: #01341a;
  font-weight: 700;
  font-size: 14px;
  display: inline;
  text-align: left;
  & :hover {
    text-decoration: none;
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 100px;
  }
`;
