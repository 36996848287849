import React from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';

import BulletPoint from './BulletPoint';
import YellowArrow from '../images/yellow-arrow.svg';
import YellowTick from '../images/yellow-tick.svg';
import { Recommendation } from '../types';

interface Props {
  recommendation: Recommendation;
  setPillRegimenDrawerOpen: any;
  setPillDrawerOpen: any;
  slideInfoFromPrismic: any;
}
export default function SlideBottomBox({
  recommendation,
  setPillRegimenDrawerOpen,
  setPillDrawerOpen,
  slideInfoFromPrismic,
}: Props) {
  if (!slideInfoFromPrismic) return null;
  const { regimen } = recommendation;

  const {
    data: { negatives, positives },
  } = slideInfoFromPrismic;

  console.log(recommendation);

  return (
    <BottomBoxContainer>
      <PStyled style={{ marginTop: 0, paddingTop: 5 }}>
        Your recommended regimen:
      </PStyled>
      <H2styled>
        {regimen.daysOn} days on {regimen.daysOff} days off
      </H2styled>
      <H2styledLink onClick={() => setPillRegimenDrawerOpen(true)}>
        Learn more about your pill regimen
      </H2styledLink>
      <PStyled>Why we've recommended this pill:</PStyled>
      {positives.map((p, i) => (
        <BulletPoint key={i} Icon={YellowTick} text={p.description[0].text} />
      ))}
      <PStyled>Things to consider with this pill:</PStyled>
      {negatives.map((p, i) => (
        <BulletPoint key={i} Icon={YellowArrow} text={p.description[0].text} />
      ))}
      <StyledLink onClick={() => setPillDrawerOpen(true)}>
        Learn more about your recommendation
      </StyledLink>
    </BottomBoxContainer>
  );
}

const StyledLink = styled(D.P)`
  cursor: pointer;
  width: fit-content;
  margin-bottom: 0;
  margin-top: 0px;
  padding-top: 6px;
  border-bottom: 2px solid #01341a;
  color: #01341a;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const PStyled = styled(D.P)`
  font-size: 14px;
  line-height: 14px;
  color: #00391e;
  opacity: 0.6;
  margin: 10px 0;
  padding-top: 16px;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const H2styled = styled(D.H2)`
  font-size: 16px;
  z-index: 1;
  font-weight: 700;
  padding-bottom: 10px;
  @media only screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;

const H2styledLink = styled(D.H2)`
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  cursor: pointer;
  @media only screen and (max-width: 1000px) {
    font-size: 12px;
  }
`;

const BottomBoxContainer = styled.div`
  height: auto;
  width: 100%;
  padding: 30px 40px;
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;
