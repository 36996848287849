import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import { Recommendation } from '../types';
import useBoxPrice from './useBoxPrice';

interface Props {
  recommendation: Recommendation;
  match: number;
}

export default function SlideTopBox({ recommendation, match }: Props) {
  const matchString = ['STRONGEST MATCH', 'SECOND MATCH', 'THIRD MATCH'][match];
  const [price, setPrice] = useState(0);

  useEffect(() => {
    useBoxPrice({ id: recommendation.id, count: 1 }).then(res => {
      setPrice(res);
    });
  }, []);
  const {
    name,
    progestinDose,
    estrogenDose,
    fullname,
    packsize,
  } = recommendation;
  const months = Math.round(packsize / 30);
  const isFirstSlide = match === 0;
  return (
    <OuterTopBox isFirstSlide={isFirstSlide}>
      <TopBox>
        <MatchBox isFirstSlide={isFirstSlide}>{matchString}</MatchBox>
        <H2styled>{name}</H2styled>
        <PStyled>
          {progestinDose} mg of progestin {estrogenDose} mg of estrogen
        </PStyled>
        <PStyled2> Also known as {fullname}</PStyled2>
        <PStyled2Caps>
          3 months supply: <span>£{price}</span>
        </PStyled2Caps>
      </TopBox>
    </OuterTopBox>
  );
}

const PStyled = styled(D.P)`
  font-size: 12px;
  line-height: 14px;
  color: #00391e;
  opacity: 0.6;
  margin: 8px 0;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const PStyled2 = styled(D.P)`
  font-size: 12px;
  line-height: 14px;
  color: #00391e;
  margin: 8px 0;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const PStyled2Caps = styled(D.P)`
  font-size: 14px;
  line-height: 14px;
  color: #00391e;
  margin-top: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }

  span {
    font-weight: bold;
  }
`;

interface MatchBox {
  isFirstSlide: boolean;
}

const MatchBox = styled(D.H2)`
  font-size: 13px;
  padding: 7px;
  position: absolute;
  right: 2%;
  top: 11%;
  width: fit-content;
  height: fit-content;
  text-transform: uppercase;
  width: initial;
  height: unset;
  background-color: ${({ isFirstSlide }: MatchBox) =>
    isFirstSlide ? '#F6D184' : '#CAE2C3'};
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

const H2styled = styled(D.H2)`
  font-size: 18px;
  z-index: 1;
  width: 57%;
  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const TopBox = styled.div`
  height: 100%;
  width: 100%;
  border: 2px solid #00391e;
  padding: 20px;
`;

interface OuterTopBox {
  isFirstSlide: boolean;
}

const OuterTopBox = styled.div`
  width: 102%;
  margin-top: -5px;
  margin-left: -1%;
  position: relative;
  border: ${({ isFirstSlide }: OuterTopBox) =>
    isFirstSlide ? '10px solid #e4ac43' : '10px solid #EAF5E8'};
  outline-color: black;
  outline-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;
