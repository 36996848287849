import React, { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './Context';
import Slide from './RecommendationSlideDesktop';
import { PillFromPrismic, Recommendation } from '../types';

interface Props {
  translate: number;
  transition: number;
  width: number;
  activeSlideIndex: number;
  handlePrevArrowClick: (a: any) => void;
  handleNextArrowClick: (a: any) => void;
  setPillRegimenDrawerOpen: (a: boolean) => void;
  setPillDrawerOpen: (a: boolean) => void;
  pillRegimenDrawerOpen: boolean;
  allPills: any[];
}

export default function RecommendationSlider({
  translate,
  transition,
  width,
  activeSlideIndex,
  handlePrevArrowClick,
  handleNextArrowClick,
  setPillRegimenDrawerOpen,
  setPillDrawerOpen,
  allPills,
  pillRegimenDrawerOpen,
}: Props) {
  const { state } = useContext(Context);
  const recommendations = Object.values(state.recommendation);

  return (
    <Container
      transition={transition}
      translateX={translate}
      activeSlideIndex={activeSlideIndex}
      width={width}
      handlePrevArrowClick={handlePrevArrowClick}
      handleNextArrowClick={handleNextArrowClick}
    >
      {recommendations.map((recommendation: Recommendation, i: number) => {
        const slideInfoFromPrismic = allPills.find((p: PillFromPrismic) => {
          return p.id.includes(recommendation.contentId);
        });

        return (
          <Slide
            key={i}
            recommendation={recommendation}
            handlePrevArrowClick={handlePrevArrowClick}
            handleNextArrowClick={handleNextArrowClick}
            match={i}
            activeSlideIndex={activeSlideIndex}
            slideInfoFromPrismic={slideInfoFromPrismic}
            setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
            setPillDrawerOpen={setPillDrawerOpen}
            pillRegimenDrawerOpen={pillRegimenDrawerOpen}
          />
        );
      })}
    </Container>
  );
}

interface Container {
  translateX: number;
  transition: number;
  width: number;
  handlePrevArrowClick: any;
  handleNextArrowClick: any;
  activeSlideIndex: number;
}

const Container = styled.div`
  transform: translateX(-${({ translateX }: Container) => translateX}px);
  transition: transform ${({ transition }: Container) => transition}s ease-out;
  width: ${({ width }: Container) => width}px;
  margin-left: ${({ activeSlideIndex }: Container) =>
    activeSlideIndex === 2 ? 105 : 60}px;
  display: flex;
  @media only screen and (max-width: 1220px) {
    width: ${({ width }: Container) => width + 220}px;
    margin-left: ${({ activeSlideIndex }: Container) =>
      activeSlideIndex > 0 ? -65 : 0}px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    padding-top: 0;
  }
`;
