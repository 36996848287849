import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Context } from './Context';
import { PillFromPrismic } from '../types';
import { navigate } from 'gatsby';
import RecommendationSlideMobile from './RecommendationSlideMobile';

interface Props {
  activeSlidePill: PillFromPrismic;
  setPillRegimenDrawerOpen: any;
  setPillDrawerOpen: any;
  setActiveSlideId: (a: any) => void;
  allPills: any;
}

export default function RecommendationSliderContainerMobile({
  activeSlidePill,
  setPillRegimenDrawerOpen,
  setPillDrawerOpen,
  setActiveSlideId,
  allPills,
}: Props) {
  const { state } = useContext(Context);

  const [slideState, setSlideState] = useState({
    activeSlideIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { activeSlideIndex } = slideState;
  let recommendations;

  if (!state.recommendation) {
    localStorage.clear();
    navigate('/questionnaire-landing');
  }
  if (state.recommendation) {
    recommendations = Object.values(state.recommendation);
  }

  useEffect(() => {
    if (!recommendations[activeSlideIndex]) {
      navigate('/questionnaire');
      return;
    }
    setActiveSlideId(recommendations[activeSlideIndex].contentId);
  }, [slideState]);

  return (
    <SliderContainer>
      {recommendations &&
        recommendations.map((recommendation, i) => {
          const slideInfoFromPrismic = allPills.find((p: PillFromPrismic) =>
            p.id.includes(recommendation.contentId)
          );

          return (
            <RecommendationSlideMobile
              slideInfoFromPrismic={slideInfoFromPrismic}
              key={i}
              recommendation={recommendation}
              index={i}
              activeSlideIndex={activeSlideIndex}
              activeSlidePill={activeSlidePill}
              setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
              setPillDrawerOpen={setPillDrawerOpen}
              setSlideState={setSlideState}
            />
          );
        })}
    </SliderContainer>
  );
}

const SliderContainer = styled.div`
  background: #cae2c3;
  position: relative;
  width: 50vw;
  height: 105vh;
  margin: 0 auto;
  margin-bottom: 100px;
  overflow-x: hidden;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    overflow-x: visible;
    border: 0.5px #cae2c3 solid;
    height: auto;
  }
`;
