import React, { useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { D } from '@dayetopia/library';

import Button from './Button';
import ContentWrapper from './ContentWrapper';
import SlideTopBox from './RecommendationSlideTopBox';
import SlideBottomBox from './RecommendationBottomBox';
import { Context } from './Context';
import { Recommendation, PillFromPrismic } from '../types';

interface SlideProps {
  recommendation: Recommendation;
  index: number;
  activeSlideIndex: number;
  activeSlidePill: PillFromPrismic;
  setPillRegimenDrawerOpen: any;
  setPillDrawerOpen: any;
  setSlideState: any;
  slideInfoFromPrismic: PillFromPrismic;
}
export default function RecommendationSlideMobile({
  recommendation,
  index,
  activeSlideIndex,
  activeSlidePill,
  setPillRegimenDrawerOpen,
  setPillDrawerOpen,
  setSlideState,
  slideInfoFromPrismic,
}: SlideProps) {
  const { dispatch } = useContext(Context);
  const isActiveSlide = activeSlideIndex === index;

  function goCheckout() {
    dispatch({ type: 'setSelectedBox', data: recommendation });
    navigate('/checkout/login');
  }

  function selectSlide() {
    if (isActiveSlide) return;
    const thisSlide = { activeSlideIndex: index, translate: 0, transition: 0 };
    setSlideState(thisSlide);
  }

  return (
    <RightContent onClick={selectSlide}>
      <BeigeContainer tabIndex={index} activeSlideIndex={activeSlideIndex}>
        <SlideTopBox recommendation={recommendation} match={index} />
        <SlideBottomBox
          slideInfoFromPrismic={slideInfoFromPrismic}
          recommendation={recommendation}
          activeSlidePill={activeSlidePill}
          setPillRegimenDrawerOpen={setPillRegimenDrawerOpen}
          setPillDrawerOpen={setPillDrawerOpen}
        />
        <StyledBuyButton onClick={goCheckout}>Buy now</StyledBuyButton>
      </BeigeContainer>
    </RightContent>
  );
}

interface BeigeContainer {
  activeSlideIndex: number;
  tabIndex: number;
}

interface OuterTopBox {
  tabIndex: number;
}

const RightContent = styled(ContentWrapper)`
  max-width: none;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  margin: 30px auto;
  padding-top: 0;
`;

const OuterTopBox = styled.div`
  width: 102%;
  margin-top: -5px;
  margin-left: -1%;
  position: relative;
  border: ${({ tabIndex }: OuterTopBox) =>
    tabIndex === 0 ? '10px solid #e4ac43' : '10px solid #EAF5E8'};
  outline-color: black;
  outline-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const BeigeContainer = styled.div`
  background: ${({ activeSlideIndex, tabIndex }: BeigeContainer) =>
    activeSlideIndex === tabIndex ? '#fefcea ' : '#EAF5E8'};
  position: relative;
  outline: none;
  border: 2px solid #00391e;
  box-sizing: border-box;
  border-radius: 1px;
  width: 88%;
  height: auto;
  margin-top: ${({ activeSlideIndex, tabIndex }: BeigeContainer) =>
    activeSlideIndex === tabIndex ? '0' : '-2%'};

  ${({ activeSlideIndex, tabIndex }: BeigeContainer) => {
    const isActiveSlide = activeSlideIndex === tabIndex;
    return `opacity:${isActiveSlide ? 1 : 0.5};
    margin-top: ${isActiveSlide ? '0' : '-2%'};
    background: ${isActiveSlide ? '#fefcea ' : '#EAF5E8'};
    pointer-events: ${isActiveSlide ? ' auto' : 'none'};
    `;
  }}

  @media only screen and (max-width: 1024px) {
    width: 90%;
    height: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

const StyledBuyButton = styled(Button)`
  margin: 15px;
  margin-top: 0;
  display: flex;
  align-self: flex-end;
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
`;
