import React from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
export default function BulletPoint({ Icon, text }) {
  return (
    <Wrapper>
      <Icon style={{ minWidth: 20, minHeight: 20 }} />
      <PStyled> {text}</PStyled>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const PStyled = styled(D.P1)`
  font-size: 14px;
  color: #00391e;
  margin: 5px;
  margin-left: 15px;
`;
