import React from 'react';
import { RichText } from 'prismic-reactjs';
import { D } from '@dayetopia/library';

export default function PillInfoDrawer({
  pillDrawerOpen,
  setPillDrawerOpen,
  activePillObj,
}) {
  return (
    <D.Drawer
      drawerOpen={pillDrawerOpen}
      setDrawerOpen={setPillDrawerOpen}
      title=""
    >
      <div className="rich-text">
        {activePillObj && RichText.render(activePillObj.data.name)}
        {activePillObj && RichText.render(activePillObj.data.full_description)}
      </div>
    </D.Drawer>
  );
}
